import { render, staticRenderFns } from "./MyInfo.vue?vue&type=template&id=2565c1fa&scoped=true&"
import script from "./MyInfo.vue?vue&type=script&lang=js&"
export * from "./MyInfo.vue?vue&type=script&lang=js&"
import style0 from "./MyInfo.vue?vue&type=style&index=0&id=2565c1fa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2565c1fa",
  null
  
)

export default component.exports