<template>
  <div>     
    <nav-bar-back class="detail-nav">
    <div class="title" slot="title">
     <div slot="center">个人资料</div>
    </div>
  </nav-bar-back>
    <van-cell-group class="cell">
    <van-cell  >
        <div class="item">
              <!-- <input type="file" id="file" class="filepath" accept="image/*"   capture="camera" multiple  @change="changeFile($event)" >
      -->
  <p>头像</p>


    <div>

        <input type="file" id="file" class="filepath" accept="image/*"   multiple  @change="changeFile($event)" >
        <div>
            <img v-if='!avatar' src="~assets/images/common/Avator.png" />
            <img v-else-if="src" :src="src" class='img'/>
            <img v-else :src="avatar" class='img' />


        </div>
         <!-- <van-uploader v-model="fileList" type="file"  :after-read="afterRead"  /> -->
     
      </div>


        </div>
    </van-cell>
</van-cell-group>
 <van-cell-group class="cell">
    <van-cell  >
        <div class="item">
  <p>姓名</p>
  <p>{{name}}</p>
        </div>
    </van-cell>
</van-cell-group>
<van-cell-group class="cell">
    <van-cell is-link :to="{ path: '/Audit', query: { id: 1 }}" >
        <div class="item">
  <p>手机号码</p>
  <p>{{phone}}</p>
        </div>
    </van-cell>
</van-cell-group>
<van-cell-group class="cell">
    <van-cell is-link :to="{ path: '/IDCard', query: { id: 2 }}">
        <div class="item">
  <p>身份绑定</p>
  <p>{{status}}</p>
        </div>
    </van-cell>
</van-cell-group>
<!--  -->


<van-cell-group class="cell" v-if="c_name" >
    <van-cell is-link  @click='openName'>
        <div class="item">
  <p>紧急联系人姓名</p>
  <p>{{c_name}}</p>
        </div>
    </van-cell>
</van-cell-group>

<van-cell-group class="cell" v-if="contact">
    <van-cell is-link @click='openPhone'>
        <div class="item">
  <p>紧急联系人号码</p>
  <p>{{contact}}</p>
        </div>
    </van-cell>
</van-cell-group>


<van-dialog
  v-model="show"
  title="更改联系人号码"
  class="dialog"
  @confirm="Phone"
  show-cancel-button
>
  <van-field v-model="phoneInput" placeholder="请输入手机号码" class="phoneInput"/>
  <!-- <img src="https://img.yzcdn.cn/vant/apple-3.jpg"> -->
</van-dialog>



<van-dialog
  v-model="show2"
  title="更改联系人姓名"
  class="dialog"
  @confirm="contactName"
  show-cancel-button
>
  <van-field v-model="NameInput" placeholder="请输入名字" class="phoneInput"/>
  <!-- <img src="https://img.yzcdn.cn/vant/apple-3.jpg"> -->
</van-dialog>


  </div>
</template>

<script>
import { Cell, CellGroup, Toast,Dialog ,Field} from 'vant';
import NavBarBack from 'common/navbar/NavBarBack'
import {upLoadImag,userForAvator,userForContact,userForName} from "network/identify";
import {user} from "network/login";
// upLoadImag
export default {
  components: {
      NavBarBack,
       [Cell.name]:Cell,
       [CellGroup.name]:CellGroup,
       [Field.name]:Field,
          [Dialog.Component.name]: Dialog.Component,

  },
  data () {
      return {
          show:false,
          show2:false,

          name:'',
          phone:'',
          phoneInput:'',
          NameInput:'',
          status:'已绑定',
          avatar:'',
           c_name:'',
           contact:'',
           src:'',
          file:null,
      }
  },
  methods: {
      openPhone(){
          this.show=true
      },
      openName(){

       this.show2=true
      },
contactName(){

   if(this.NameInput){
        
  userForName(this.NameInput).then(res=>{
          this.c_name=this.NameInput
        Toast.success('修改成功')
    })

   }else{
       Toast.fail("名字不能为空")
   }
},

      Phone(){
 
        //  var phone = document.getElementById('phone').value;
    if(!(/^1[3|4|5|7|8]\d{9}$/.test( this.phoneInput))){ 
        // alert("手机号码有误，请重填"); 
        Toast.fail("手机号码有误，请重填" )
        // addPhone()
        this.show=false
        return false; 
    } 

    userForContact(this.phoneInput).then(res=>{
          this.contact=this.phoneInput
        Toast.success('修改成功')
    })
      
     
      

  },
      

      changeFile(e){
        // this.src='';
        this.file=e.target.files;
        // console.log(this.file[0])

         this.file = e.target.files[0]
        var reader = new FileReader()
        var that = this
        reader.readAsDataURL( this.file)
        reader.onload = function(e) {
          that.src = this.result
        }
   
       
        //访问上传照片的接口


           var formData = new FormData();
             formData.append('image', this.file);
             formData.append('type','avatar')
 

              upLoadImag(formData).then(res=>{
   
       //console.log('fffffffff133',res);  

               userForAvator(res.id).then(res=>{
                    //   console.log('fffffffff13555',res);
                   Toast.success("修改成功")
               })
            //     if(res.code==200){
            //         console.log('fffffffff1',res);  
            // //  Toast.success('上传成功');
            //     }else{
            //           console.log('fffffffff2',res);
            //     //  Toast.fail('上传失败,请重新上传');               
            //     }
               
               })
      
  
       } 
  },
  created(){
      //
    //  this.avatar= this.$route.query.avator
    //获取用户信息
        user().then(res=>{
              this.avatar=res.avatar
              this.contact=res.contact
              this.c_name=res.c_name
            //   console.log(res)
        })
      


     this.name=this.$route.query.name
     this.phone=this.$route.query.phone
  }
}
</script>

<style scoped>
.phoneInput{
 width: 80%;
 /* padding: 30px; */
 /* margin: 30px; */
 margin-top: 20px;
 margin-bottom: 20px;
 
    margin-left: auto ;
     margin-right:auto;
  background-color: #eee;

  border: none
}
.dialog{
  background-color: #fefefe
}
  .filepath{
        position: absolute;
        opacity: 0
  }
.img{
    border-radius:50%
}
.item{
    display: flex;
    justify-content: space-between
}
.cell{
    margin-top: 10px;
}
.item img{
    width: 23px;
    height: 23px;;
}
</style>





